import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { settingsState } from '../state/settingsAtom';

export const useSettings = () => {
  const [settings, setSettings] = useRecoilState(settingsState);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_API_URL + 'api/users/loginsettings?_=' + new Date().getTime());
        setSettings(res.data);
      } catch (err) {
        console.error('⚠️ Failed to load settings:', err);
      }
    };

    fetchSettings();
  }, []);

  return settings;
};