import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'settingsStorage', // ключ в localStorage
  storage: localStorage,  // можно заменить на sessionStorage при желании
});

export const settingsState = atom({
  key: 'settingsState',
  default: {
    loginEnabled: true,
    registerEnabled: true,
    allowUserRequests: true,
  },
  effects_UNSTABLE: [persistAtom],
});